@import "shared/styles/core";

.top {
  background: var(--surface-primary);
  display: none;
}

.body {
  grid-column-start: 1;
  grid-column-end: 3;
  padding: ($spacing * 10) 0;
}

.mobile {
  width: 100%;
}

.desktop {
  display: none;
}

@include media-breakpoint-up(lg) {
  .top {
    display: block;
    padding: ($spacing * 14) 0;
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
    width: 100%;
  }
}
